<template>
<!-- 游戏线路管理管理页 -->
  <div class="birthdayRight">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>游戏线路管理管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>游戏线路名称：</span>
      <el-input
        v-model="formData.title"
        placeholder="请输入游戏线路名称"
      ></el-input>
      <!-- <span style="margin-left:15px;">游戏线路管理状态：</span>
        <el-select v-model="optStatus" placeholder="请选择">
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      <el-button type="primary" class="btn" @click="searchGameLine()"
        >查询</el-button
      >

      <el-button
        type="primary"
        class="btn"
        @click="$router.push('/addGameLine')"
      >
        添加
      </el-button>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :cell-style="rowClass"  :header-cell-style="headClass"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="lineId" label="ID" width="110"> </el-table-column>
      <el-table-column prop="lineName" label="线路名称" width="240" > </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="180"> </el-table-column>
      <el-table-column prop="couponName" label="优惠券名称" width="180" > </el-table-column>
      <el-table-column prop="strStatus" label="线路状态" width="180" > </el-table-column>
      <!-- <el-table-column prop="imgUrl" label="介绍图">
         <tempalte slot-scope="{ row }">
          <el-image
            style="height: 100px"
            fit="fill"
            :src="row.imgUrl"
            :preview-src-list="[row.imgUrl]"
          ></el-image>
        </tempalte>
         </el-table-column> -->
      <el-table-column label="操作" >
        <template slot-scope="{ row }">
             <el-button
            size="mini"
            type="primary"
            style="margin-left: 10px"
            @click="isAble(row.lineId,row.status)"
            v-if="row.status == 2"
          >
            启用
          </el-button>
            <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="isAble(row.lineId,row.status)"
            v-if="row.status == 1"
          >
            禁用
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="dialogVisible3 = true"
          >
            删除
          </el-button>
        <el-button type="primary" size="mini" @click="goEdit(row.lineId)">编辑</el-button>
        <el-button type="primary" size="mini" @click="goDetail(row.lineId)">线路管理</el-button>
               <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="30%"
              :before-close="handleClose">
              <span>是否启用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible = false">取 消</el-button>
           <el-button type="primary" @click="turnOn()">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible2"
     width="30%"
     :before-close="handleClose">
     <span>是否禁用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible2 = false">取 消</el-button>
           <el-button type="primary" @click="turnOff()">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible3"
     width="30%"
     :before-close="handleClose">
     <span>是否删除？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible3 = false">取 消</el-button>
           <el-button type="primary" @click="removePlace(row.lineId)">确 定</el-button>
      </span>
    </el-dialog>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { gameLineListAPI,addLineAPI,manageLineAPI,lineDetailAPI} from "../../../api/smart";
export default {
  name: "activeList",

  data() {
    return {
      gameStatus:[],
        tableData:[],
      showAddress: false,
      address: "",
      formData: {
        status: null,
        title: "",
        pageSize: 10,
        currentPage: 1,
      },
      multipleSelection: [],
      tableData: [],
      pagination: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      detailData:[],
      gameId:'',
      lineId:'',
      abl:'',
      statusOption:[{
          value: -2,
          label: '全部'
        }, {
          value: 1,
          label: '正常'
        }, {
          value: 0,
          label: '禁用'
        }],
        optStatus:'',
    };
  },
  created() {
    console.log(this.tableData);
    this.getGameLineList();
  },
  methods: {
    // 表头样式设置
            headClass () {
                 return 'text-align: center;'
            },
            // 表格样式设置
            rowClass () {
                return 'text-align: center;'
            },
    goDetail(row){
      this.$router.push(`/gameLineManage?lineId=${row}`)
    },
      isAble(row,status){
        console.log('isAble',row)
        this.lineId = row
        if(status == 1){
            this.dialogVisible2 = true
        }else{
            this.dialogVisible = true
        }
      },
      closeModal(){
        this.turnOn()
        this.dialogVisible=false
      },
       handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //搜索游戏线路管理列表
      async searchGameLine(){
        const {data} = await gameLineListAPI({
            pageSize:this.formData.pageSize,
          currentPage:this.formData.currentPage,
          lineName:this.formData.title
         })
        this.tableData = data.list;
      },
      //游戏线路管理详情
      goEdit(row,row2,row3){
          console.log('goEdit',row)
         this.$router.push(`/addGameLine?lineId=${row}`)
      },
      //游戏线路管理列表
      async getGameLineList(){
          const {data} = await gameLineListAPI({
              pageSize:this.formData.pageSize,
              currentPage:this.formData.currentPage,
              lineName:this.formData.title,
          })
          console.log(data,'data');
          this.tableData = data.list;
          this.pagination = data.pagination
      },
      //启动
        async turnOn(row,status){
        await manageLineAPI({
              lineId:this.lineId,
              status:1,
          })
        setTimeout(() => {
            this.dialogVisible =false
        }, 200);
            this.$message({ message: "启用成功", type: "success" }); 
            this.getGameLineList()
      },
      //禁用
      async turnOff(){
          await manageLineAPI({
            lineId:this.lineId,
            status:2,
          })
            setTimeout(() => {
                this.dialogVisible2 =false
        }, 200);
                this.$message({ message: "禁用成功", type: "success" }); 
                this.getGameLineList()
      },
      //删除游戏线路管理
      async removePlace(row){
          console.log(row,'row')
          await manageLineAPI({
              lineId:row,
              status:0,
          })
         setTimeout(() => {
            this.dialogVisible3 =false
            this.$message({ message: "删除成功", type: "success" }); 
        }, 200);
          this.getGameLineList();
      },
    onShowAddress(id) {
      this.address = "components/navPages/navPages?activityId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
       var inputElement =  document.getElementById("wrapper");
       //选中input框的内容
       inputElement.select();
        // 执行浏览器复制命令
       document.execCommand("Copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      var data = [];
      arr.forEach((item) => {
        data.push(item.activityId);
      });
      this.multipleSelection = data;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getGameLineList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getGameLineList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.birthdayRight {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .btn_box {
    float: left;
    margin-top: 50px;
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 200px;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .tips {
    // text-align: right;
    color: orangered;
  }
  .grade_list {
    padding: 0 10px 10px;
    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .form_item {
        display: flex;
        .el-form-item {
          flex: 1;
          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>